import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Signup from './components/Signup';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import MediaList from './components/MediaList';
import { db } from './firebase';
import { doc, setDoc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import './App.css';

const Home = ({ searchType, handleSwitchType, currentPage, setCurrentPage, sortOption, setSortDropdownVisible, sortDropdownVisible, setSortOption, itemsPerPage }) => {
  const { currentUser } = useAuth();

  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [books, setBooks] = useState([]);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    const fetchData = async () => {
      const docRef = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setMovies(data.movies?.map(item => ({ ...item, addedDate: item.addedDate.toDate() })) || []);
        setTvShows(data.tvShows?.map(item => ({ ...item, addedDate: item.addedDate.toDate() })) || []);
        setBooks(data.books?.map(item => ({ ...item, addedDate: item.addedDate.toDate() })) || []);
      } else {
        await setDoc(docRef, { movies: [], tvShows: [], books: [] });
      }
    };

    fetchData();
  }, [currentUser]);

  const handleAdd = async (item) => {
    if (!currentUser) return;
    const docRef = doc(db, 'users', currentUser.uid);
    const newItem = { ...item, addedDate: new Date() };

    if (searchType === 'movie') {
      await updateDoc(docRef, { movies: arrayUnion(newItem) });
      setMovies([...movies, newItem]);
    } else if (searchType === 'tv') {
      await updateDoc(docRef, { tvShows: arrayUnion(newItem) });
      setTvShows([...tvShows, newItem]);
    } else if (searchType === 'book') {
      await updateDoc(docRef, { books: arrayUnion(newItem) });
      setBooks([...books, newItem]);
    }
  };

  const handleRemove = async (index) => {
    if (!currentUser) return;
    const docRef = doc(db, 'users', currentUser.uid);
    let newList = [];

    if (searchType === 'movie') {
      const newMovies = [...movies];
      const removedItem = newMovies.splice(index, 1)[0];
      await updateDoc(docRef, { movies: arrayRemove(removedItem) });
      setMovies(newMovies);
    } else if (searchType === 'tv') {
      const newTvShows = [...tvShows];
      const removedItem = newTvShows.splice(index, 1)[0];
      await updateDoc(docRef, { tvShows: arrayRemove(removedItem) });
      setTvShows(newTvShows);
    } else if (searchType === 'book') {
      const newBooks = [...books];
      const removedItem = newBooks.splice(index, 1)[0];
      await updateDoc(docRef, { books: arrayRemove(removedItem) });
      setBooks(newBooks);
    }
  };

  const isAdded = (id) => {
    if (searchType === 'movie') {
      return movies.some(movie => movie.id === id);
    } else if (searchType === 'tv') {
      return tvShows.some(tvShow => tvShow.id === id);
    } else if (searchType === 'book') {
      return books.some(book => book.id === id);
    }
    return false;
  };

  const getPageTitle = () => {
    if (searchType === 'movie') return 'My Movies';
    if (searchType === 'tv') return 'My Shows';
    if (searchType === 'book') return 'My Books';
    return '';
  };

  return (
    <>
      <h2 className="page-title">{getPageTitle()}</h2>
      <SearchBar searchType={searchType} handleAdd={handleAdd} isAdded={isAdded} />
      {searchType === 'movie' && (
        <MediaList
          list={movies}
          handleRemove={handleRemove}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          sortOption={sortOption}
          setSortDropdownVisible={setSortDropdownVisible}
          sortDropdownVisible={sortDropdownVisible}
          handleSortChange={setSortOption}
        />
      )}
      {searchType === 'tv' && (
        <MediaList
          list={tvShows}
          handleRemove={handleRemove}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          sortOption={sortOption}
          setSortDropdownVisible={setSortDropdownVisible}
          sortDropdownVisible={sortDropdownVisible}
          handleSortChange={setSortOption}
        />
      )}
      {searchType === 'book' && (
        <MediaList
          list={books}
          handleRemove={handleRemove}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          sortOption={sortOption}
          setSortDropdownVisible={setSortDropdownVisible}
          sortDropdownVisible={sortDropdownVisible}
          handleSortChange={setSortOption}
        />
      )}
    </>
  );
};

const AppContent = () => {
  const location = useLocation();
  const showHeader = location.pathname !== '/login' && location.pathname !== '/signup';
  
  const [searchType, setSearchType] = useState('movie');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [sortOption, setSortOption] = useState('recent');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const handleSwitchType = (type) => {
    setSearchType(type);
    setCurrentPage(1);
    setSortDropdownVisible(false); // Close the sort dropdown when switching types
  };

  return (
    <div className="media-list-container">
      <div className="content-wrapper">
        {showHeader && <Header handleSwitchType={handleSwitchType} isMobileMenuVisible={isMobileMenuVisible} setIsMobileMenuVisible={setIsMobileMenuVisible} />}
        <Routes>
          <Route path="/" element={<PrivateRoute><Home searchType={searchType} handleSwitchType={handleSwitchType} currentPage={currentPage} setCurrentPage={setCurrentPage} sortOption={sortOption} setSortDropdownVisible={setSortDropdownVisible} sortDropdownVisible={sortDropdownVisible} setSortOption={setSortOption} itemsPerPage={itemsPerPage} /></PrivateRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
