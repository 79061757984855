import React, { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles/Header.css';

const Header = ({ handleSwitchType, isMobileMenuVisible, setIsMobileMenuVisible }) => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileMenuRef = useRef(null);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuRef, setIsMobileMenuVisible]);

  const showMediaButtons = location.pathname === '/';

  return (
    <header>
      <h1 className="logo">Gist</h1>
      {currentUser && (
        <div className="nav-buttons">
          <button className="mobile-menu-button" onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>☰</button>
          <div className={`mobile-menu-dropdown ${isMobileMenuVisible ? 'show' : ''}`} ref={mobileMenuRef}>
            {showMediaButtons && (
              <>
                <button onClick={() => { handleSwitchType('movie'); setIsMobileMenuVisible(false); }}>
                  <span className="icon">🎬</span>Movies
                </button>
                <button onClick={() => { handleSwitchType('tv'); setIsMobileMenuVisible(false); }}>
                  <span className="icon">📺</span>Shows
                </button>
                <button onClick={() => { handleSwitchType('book'); setIsMobileMenuVisible(false); }}>
                  <span className="icon">📚</span>Books
                </button>
              </>
            )}
            <button className="logout-button" onClick={() => { handleLogout(); setIsMobileMenuVisible(false); }}>🔒 Logout</button>
          </div>
        </div>
      )}
      {currentUser && (
        <div className="desktop-nav-buttons">
          {showMediaButtons && (
            <>
              <button onClick={() => handleSwitchType('movie')}>
                <span className="icon">🎬</span>Movies
              </button>
              <button onClick={() => handleSwitchType('tv')}>
                <span className="icon">📺</span>Shows
              </button>
              <button onClick={() => handleSwitchType('book')}>
                <span className="icon">📚</span>Books
              </button>
            </>
          )}
          <button className="logout-button" onClick={handleLogout}>🔒 Logout</button>
        </div>
      )}
    </header>
  );
};

export default Header;
