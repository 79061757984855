import React, { useState, useEffect, useRef } from 'react';
import './styles/SearchBar.css';
import { fetchTMDB, fetchGoogleBooks } from '../utils/api';

const SearchBar = ({ searchType, handleAdd, isAdded }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        setSearchQuery('');
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]);

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      const query = e.target.value;
      setSearchQuery(query);
      if (searchType === 'movie' || searchType === 'tv') {
        const results = await fetchTMDB(query, searchType);
        setSearchResults(results);
      } else if (searchType === 'book') {
        const results = await fetchGoogleBooks(query);
        setSearchResults(results);
      }
      setIsDropdownVisible(true);
    }
  };

  const handleAddItem = (item) => {
    handleAdd(item);
    setIsDropdownVisible(false);  // Hide the dropdown
    setSearchQuery('');           // Clear the search input
    setSearchResults([]);         // Clear the search results
  };

  const getPlaceholderText = () => {
    if (searchType === 'movie') return 'Search to add movies';
    if (searchType === 'tv') return 'Search to add shows';
    if (searchType === 'book') return 'Search to add books';
    return '';
  };

  return (
    <div className="search-container" ref={searchContainerRef}>
      <input
        type="text"
        placeholder={getPlaceholderText()}
        className="search-input"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleSearch}
        onFocus={() => setIsDropdownVisible(true)}
      />
      <span className="search-icon">&#128269;</span>
      {isDropdownVisible && searchResults.length > 0 && (
        <div className="search-dropdown">
          {searchResults.map((result, index) => (
            <div key={index} className="search-result-item">
              <img src={result.imageUrl} alt={result.title} className="dropdown-poster" />
              <span className="result-text">{result.title}</span>
              {isAdded(result.id) ? (
                <span className="added-label">Added</span>
              ) : (
                <button className="add-button" onClick={() => handleAddItem(result)}>+</button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
