import React from 'react';
import './styles/MediaList.css';
import SortDropdown from './SortDropdown';

const MediaList = ({ list, handleRemove, currentPage, itemsPerPage, setCurrentPage, sortOption, setSortDropdownVisible, sortDropdownVisible, handleSortChange }) => {
  const getSortedList = (list) => {
    switch (sortOption) {
      case 'alphabetical':
        return list.slice().sort((a, b) => a.title.localeCompare(b.title));
      case 'oldest':
        return list.slice().sort((a, b) => new Date(a.addedDate) - new Date(b.addedDate));
      case 'recent':
      default:
        return list.slice().sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
    }
  };

  const sortedList = getSortedList(list);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedList.slice(indexOfFirstItem, indexOfLastItem);

  if (list.length === 0) {
    return <p className="empty-message">Your list is empty. Start adding some items!</p>;
  }

  return (
    <div>
      <ul className="media-list">
        {currentItems.map((item, index) => (
          <li key={index} className="media-item">
            <div className="media-info">
              <img src={item.imageUrl} alt={item.title} className="media-poster" />
              <span>{item.title}</span>
            </div>
            <button className="remove-button" onClick={() => handleRemove(indexOfFirstItem + index)}>&#8722;</button>
          </li>
        ))}
      </ul>
      <div className="pagination">
        <button
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ←
        </button>
        <div className="pagination-content">
          <span>{`${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, list.length)} of ${list.length}`}</span>
          <SortDropdown
            sortOption={sortOption}
            setSortDropdownVisible={setSortDropdownVisible}
            sortDropdownVisible={sortDropdownVisible}
            handleSortChange={handleSortChange}
          />
        </div>
        <button
          className={`pagination-button ${indexOfLastItem >= list.length ? 'disabled' : ''}`}
          onClick={() => indexOfLastItem < list.length && setCurrentPage(currentPage + 1)}
          disabled={indexOfLastItem >= list.length}
        >
          →
        </button>
      </div>
    </div>
  );
};

export default MediaList;
