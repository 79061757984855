import React, { useEffect, useRef } from 'react';
import './styles/SortDropdown.css';

const SortDropdown = ({ sortOption, setSortDropdownVisible, sortDropdownVisible, handleSortChange }) => {
  const sortDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sortDropdownRef, setSortDropdownVisible]);

  const handleOptionClick = (option) => {
    handleSortChange(option);
    setSortDropdownVisible(false); // Close the dropdown
  };

  return (
    <div className="filter-sort-container" ref={sortDropdownRef}>
      <button className="filter-sort-button" onClick={() => setSortDropdownVisible(!sortDropdownVisible)}>⚙️</button>
      {sortDropdownVisible && (
        <div className="sort-dropdown">
          <button onClick={() => handleOptionClick('recent')}>
            Most Recent {sortOption === 'recent' && <span className="check-mark">✔️</span>}
          </button>
          <button onClick={() => handleOptionClick('oldest')}>
            Oldest {sortOption === 'oldest' && <span className="check-mark">✔️</span>}
          </button>
          <button onClick={() => handleOptionClick('alphabetical')}>
            Alphabetical {sortOption === 'alphabetical' && <span className="check-mark">✔️</span>}
          </button>
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
