const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const GOOGLE_BOOKS_API_KEY = process.env.REACT_APP_GOOGLE_BOOKS_API_KEY;
const TMDB_IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/w500';

export const fetchTMDB = async (query, type) => {
  const url = `https://api.themoviedb.org/3/search/${type}?api_key=${TMDB_API_KEY}&query=${query}`;
  const response = await fetch(url);
  const data = await response.json();
  return data.results.map(result => ({
    id: result.id,
    title: result.title || result.name,
    imageUrl: result.poster_path ? `${TMDB_IMAGE_BASE_URL}${result.poster_path}` : 'https://via.placeholder.com/100x150.png?text=No+Image'
  }));
};

export const fetchGoogleBooks = async (query) => {
  const url = `https://www.googleapis.com/books/v1/volumes?q=${query}&key=${GOOGLE_BOOKS_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  return data.items.map(item => ({
    id: item.id,
    title: item.volumeInfo.title,
    imageUrl: item.volumeInfo.imageLinks ? item.volumeInfo.imageLinks.thumbnail : 'https://via.placeholder.com/100x150.png?text=No+Image'
  }));
};

